














import Vue, { PropOptions } from 'vue';

export type Columns = Blok & {
  title: string;
  items: Blok[];
  marginTop: number;
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<Columns>,
  },
  computed: {
    cssVars(): CSSVars {
      return {
        '--columns': this.blok.items.length,
        '--columns-margin-top': `${this.blok.marginTop}rem`,
      };
    },
  },
});
